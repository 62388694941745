<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="selectedItems"
    :chips="chips"
    :deletable-chips="deletableChips"
    :outlined="outlined"
    :items="getItems"
    :height="getHeight"
    :label="label"
    :item-text="getItemText"
    item-value="id"
    :multiple="multiple"
    :loading="isLoading"
    :dense="dense"
    :disabled="disabled"
    :rules="rules"
    hide-details="auto"
    class="plus-field"
    :no-data-text="noDataText ? noDataText : $trans('nothing_here')"
    clearable
    :attach="'#' + uniqueId"
    :menu-props="{ top: forceListToTop, offsetY: true }"
    @change="onChangeHandle"
  >
    <template #no-data>
      <div class="v-subheader theme--light">
        {{ $trans("nothing_found_here") }}
      </div>
    </template>

    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <template v-if="chips || smallChips">
          <v-chip
            :small="smallChips"
            :disabled="disabled"
            :close="deletableChips"
            @click:close="removeChip(data.item)"
          >
            <span class="text-truncate">{{ getItemText(data.item) }}</span>
          </v-chip>
        </template>
        <template v-else>
          <span class="text-truncate">{{ getItemText(data.item) }}</span>
        </template>
      </div>
    </template>

    <template #item="data">
      <div class="d-flex align-center pointer my-2">
        <span class="text-break cd-v-select-line-height">{{
          getItemText(data.item)
        }}</span>
      </div>
    </template>

    <template v-if="isPlusButton" #append-outer>
      <div :id="uniqueId"></div>
      <slot />
      <div v-if="!hideActions">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div class="outlined-icon ml-2" @click="handleAdd" v-on="on">
              <v-icon>$plus</v-icon>
            </div>
          </template>
          {{ $trans("add") }}
        </v-tooltip>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import confirmDialogTypes from "@/components/ConfirmDialogs/confirmDialogTypes";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSizes from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import sharedFieldActions from "@/lib/calendesk-js-library/mixins/sharedFieldActions";
import sharedField from "@/calendesk/mixins/sharedField";

export default {
  name: "PlusField",
  mixins: [sharedFieldActions, sharedField],
  props: {
    action: {
      type: String,
      default: null,
    },
    getter: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    dialogType: {
      type: String,
      default: null,
    },
    isConfigDialog: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    dialogSize: {
      type: [Number, String],
      default: dialogSizes.MIDDLE,
    },
    dialogTitle: {
      type: String,
      default: null,
    },
    dialogCtaButtonAction: {
      type: String,
      default: null,
    },
    dialogCtaButtonIcon: {
      type: String,
      default: null,
    },
    dialogCtaButtonTitle: {
      type: String,
      default: null,
    },
    dialogData: {
      type: [Object, Array],
      default: null,
    },
    isPlusButton: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    forceListToTop: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 400,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: null,
    },
    getItemText: {
      type: Function,
      default: (item) => `(${trans("id")}: ${item.id}) ${item.name}`,
    },
    reloadOn: {
      type: [String, Number, Object],
      default: null,
    },
    keepMenuOpenOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    getItems() {
      return this.$store.getters[this.getter];
    },
    getHeight() {
      if (
        this.selectedItems === null ||
        this.selectedItems === undefined ||
        this.selectedItems.length === 0
      ) {
        return 0;
      }

      return null;
    },
  },
  watch: {
    reloadOn() {
      this.getData(true);
    },
    getItems() {
      if (!this.multiple && this.selectedItems) {
        // The case when a selected item has been removed.
        const find = this.getItems.find(
          (item) => item.id === this.selectedItems
        );
        if (!find) {
          this.selectedItems = null;
        }
      }
    },
  },
  created() {
    this.getData();
  },
  methods: {
    onChangeHandle() {
      if (!this.keepMenuOpenOnSelect) {
        this.$refs.autocomplete.isFocused = false;
      }

      this.$emit("input", this.selectedItems);
    },
    handleAdd() {
      if (this.isConfigDialog) {
        this.openConfirmDialog({
          type: confirmDialogTypes[this.dialogType],
          width: this.width,
          persistent: this.persistent,
          data: this.dialogData,
        });
      } else {
        this.openDialog({
          type: dialogTypes[this.dialogType],
          size: this.dialogSize,
          title: this.dialogTitle,
          ctaButtonAction: this.dialogCtaButtonAction,
          ctaButtonIcon: this.dialogCtaButtonIcon,
          ctaButtonTitle: this.dialogCtaButtonTitle,
          data: this.dialogData,
        });
      }
    },
    async getData(forceReload) {
      const isForceRefresh =
        forceReload || !this.getItems || this.getItems.length === 0;

      if (isForceRefresh) {
        try {
          this.isLoading = true;
          await this.$store.dispatch(this.action);
        } catch (err) {
          console.log(err);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.plus-field {
  padding: 0;
  margin: 0;
}
</style>
